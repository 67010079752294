import { defineComponent, reactive, toRefs } from 'vue';
import { ElRow, ElCol, ElSwitch, ElSpace } from 'element-plus';
import UploadType from './UploadType.vue';
import { useMessage } from '@/hooks/web/useMessage';
import api from '@/api/instruction';
export default defineComponent({
  name: 'Notice',
  components: {
    UploadType,
    ElRow,
    ElCol,
    ElSwitch,
    ElSpace
  },
  emits: ['update:modelValue', 'update:uploadType'],
  props: {
    noticeLabel: {
      type: String,
      default: ''
    },
    imei: {
      type: String
    }
  },
  setup(props) {
    const messageBox = useMessage();
    const refData = reactive({
      isRemind: 0,
      reportType: 1
    });
    async function sendMsg(params) {
      const result = await api.sendFamilyPhoneMsg({
        ...refData,
        ...params
      });
      messageBox[result.code == 0 ? 'success' : 'error'](`指令下发${result.msg}`);
    }
    async function getParams(commandType) {
      const {
        data,
        code,
        msg
      } = await api.getRemind({
        mdtid: props.imei,
        cmdtype: commandType,
        cmdcontent: ""
      });
      if (data) {
        refData.isRemind = data.isRemind;
        refData.reportType = data.reportType;
      }
    }
    return {
      ...toRefs(refData),
      sendMsg,
      getParams
    };
  }
});