import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_radio = _resolveComponent("el-radio");
  const _component_el_radio_group = _resolveComponent("el-radio-group");
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createElementBlock("div", null, [_ctx.checkbox.length || !_ctx.onoff ? (_openBlock(), _createBlock(_component_el_row, {
    key: 0
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 24
    }, {
      default: _withCtx(() => [_createTextVNode("请选择SOS号码：")]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 24
    }, {
      default: _withCtx(() => [_createVNode(_component_el_radio_group, {
        modelValue: _ctx.sosNumber,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.sosNumber = $event)
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.checkbox, (item, index) => {
          return _openBlock(), _createBlock(_component_el_radio, {
            label: item.val,
            key: index,
            value: item.label
          }, null, 8, ["label", "value"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue"])]),
      _: 1
    })]),
    _: 1
  })) : (_openBlock(), _createBlock(_component_el_row, {
    key: 1
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 24,
      class: "red"
    }, {
      default: _withCtx(() => [_createTextVNode(" 请先设置亲情号码 ")]),
      _: 1
    })]),
    _: 1
  }))]);
}