import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", null, [_createVNode(_component_el_row, null, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 5
    }, {
      default: _withCtx(() => [_createTextVNode("1.昵称与号码：")]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 8,
      offset: 1
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: _ctx.name1,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.name1 = $event),
        modelModifiers: {
          trim: true
        },
        type: "text",
        placeholder: "请输入昵称"
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 8,
      offset: 1
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: _ctx.phone1,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.phone1 = $event),
        modelModifiers: {
          trim: true,
          number: true
        },
        type: "number",
        placeholder: "请输入号码"
      }, null, 8, ["modelValue"])]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_el_row, null, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 5
    }, {
      default: _withCtx(() => [_createTextVNode("2.昵称与号码：")]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 8,
      offset: 1
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: _ctx.name2,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.name2 = $event),
        modelModifiers: {
          trim: true
        },
        type: "text",
        placeholder: "请输入昵称"
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 8,
      offset: 1
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: _ctx.phone2,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.phone2 = $event),
        modelModifiers: {
          trim: true,
          number: true
        },
        type: "number",
        placeholder: "请输入号码"
      }, null, 8, ["modelValue"])]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_el_row, null, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 5
    }, {
      default: _withCtx(() => [_createTextVNode("3.昵称与号码：")]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 8,
      offset: 1
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: _ctx.name3,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.name3 = $event),
        modelModifiers: {
          trim: true
        },
        type: "text",
        placeholder: "请输入昵称"
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 8,
      offset: 1
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: _ctx.phone3,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _ctx.phone3 = $event),
        modelModifiers: {
          trim: true,
          number: true
        },
        type: "number",
        placeholder: "请输入号码"
      }, null, 8, ["modelValue"])]),
      _: 1
    })]),
    _: 1
  })])]);
}