import "core-js/modules/es.array.push.js";
import api from '@/api/instruction';
import { useMessage } from '@/hooks/web/useMessage';
import { defineComponent, reactive, toRefs, defineAsyncComponent } from 'vue';
import { useDebounce } from '@/hooks/core/useDebounce';
export default defineComponent({
  name: 'WifiMac',
  components: {
    TypeSelect: defineAsyncComponent(() => import('@/components/TypeSelect/TypeSelect.vue'))
  },
  props: {
    imei: {
      type: String
    }
  },
  setup(props, {
    emit
  }) {
    const messageBox = useMessage();
    const refData = reactive({
      schoolAddress: [],
      homeAddress: [],
      loading: false,
      reg: /^[A-F0-9]{2}(-[A-F0-9]{2}){5}$|^[A-F0-9]{2}(:[A-F0-9]{2}){5}$|^[A-F0-9]{12}$|^[A-F0-9]{4}(\.[A-F0-9]{4}){2}$/
    });
    //下发
    async function sendMsg(params) {
      try {
        refData.schoolAddress.map((item, index) => {
          if (!item.value) throw {
            msg: '请填写单位wifi mac地址'
          };
          if (!refData.reg.test(item.value)) throw {
            msg: `第${index + 1}单位wifi mac地址格式错误`
          };
        });
        refData.homeAddress.map((item, index) => {
          if (!item.value) throw {
            msg: '请填写家庭wifi mac地址'
          };
          if (!refData.reg.test(item.value)) throw {
            msg: `第${index + 1}家庭wifi mac地址格式错误`
          };
        });
        const param = {
          ...params,
          commandMsg: JSON.stringify({
            homeAddress: refData.homeAddress.map(item => item.value).join('#'),
            schoolAddress: refData.schoolAddress.map(item => item.value).join('#')
          })
        };
        const result = await api.sendMsg(param);
        messageBox[result.code == 0 ? 'success' : 'error'](`指令下发${result.msg}`);
      } catch ({
        msg
      }) {
        messageBox.warning(msg);
      }
    }
    // 添加
    function add(name) {
      refData[name].push({
        value: ''
      });
    }
    // 删除
    function remove(name, active) {
      refData[name] = refData[name].filter((item, index) => index != active);
    }
    // 获取参数
    async function getParams() {
      refData.loading = true;
      const {
        data,
        code,
        msg
      } = await api.getCardCommandConf({
        mdtid: props.imei,
        commandType: 19
      });
      if (code == 0 && data) {
        const params = JSON.parse(data.data);
        refData.homeAddress = params.homeAddress.split('#').map(item => ({
          value: item
        }));
        refData.schoolAddress = params.schoolAddress.split('#').map(item => ({
          value: item
        }));
      } else {
        add('homeAddress');
        add('schoolAddress');
      }
      refData.loading = false;
    }
    // 防抖
    const [debounceRemove] = useDebounce(remove, 200);
    const [debounceAdd] = useDebounce(add, 200);
    return {
      ...toRefs(refData),
      sendMsg,
      getParams,
      debounceRemove,
      debounceAdd
    };
  }
});