import api from '@/api/instruction';
import { useMessage } from '@/hooks/web/useMessage';
import { ElCol, ElInput, ElRow, ElScrollbar, ElSwitch, ElInputNumber } from 'element-plus';
import { defineComponent, reactive, toRefs, defineAsyncComponent } from 'vue';
export default defineComponent({
  name: 'PositionMode',
  components: {
    ElRow,
    ElCol,
    ElInput,
    ElScrollbar,
    ElSwitch,
    ElInputNumber,
    TypeSelect: defineAsyncComponent(() => import('@/components/TypeSelect/TypeSelect.vue'))
  },
  props: {
    imei: {
      type: String
    }
  },
  setup(props, {
    emit
  }) {
    const messageBox = useMessage();
    const refData = reactive({
      modeType: 1,
      min: 30,
      loading: false
    });
    async function sendMsg(params) {
      try {
        if (!refData.min && refData.modeType == 3) throw {
          msg: '时间不能为空'
        };
        const param = {
          ...params,
          commandMsg: JSON.stringify(refData.modeType == 3 ? {
            modeType: refData.modeType,
            min: refData.min
          } : {
            modeType: refData.modeType
          })
        };
        const result = await api.sendMsg(param);
        messageBox[result.code == 0 ? 'success' : 'error'](`指令下发${result.msg}`);
      } catch ({
        msg
      }) {
        messageBox.warning(msg);
      }
    }
    async function getParams() {
      refData.loading = true;
      const {
        data,
        code,
        msg
      } = await api.getCardCommandConf({
        mdtid: props.imei,
        commandType: 15
      });
      if (code == 0 && data) {
        const params = JSON.parse(data.data);
        refData.modeType = params.modeType;
        refData.min = params.min ? Number(params.min) : 5;
      }
      refData.loading = false;
    }
    return {
      ...toRefs(refData),
      sendMsg,
      getParams
    };
  }
});