import api from '@/api/instruction';
import { useMessage } from '@/hooks/web/useMessage';
import { ElCol, ElInput, ElRow, ElCheckbox, ElCheckboxGroup, ElMessageBox } from 'element-plus';
import { defineComponent, reactive, toRefs, watch } from 'vue';
export default defineComponent({
  name: 'FamilyNumber',
  components: {
    ElRow,
    ElCol,
    ElInput,
    ElCheckbox,
    ElCheckboxGroup
  },
  props: {
    imei: {
      type: String
    },
    //卡类型
    isInternet: {
      type: Number,
      default: 2
    },
    //设置时间
    sendTime: {
      type: String,
      default: ""
    },
    //设备类型
    deviceType: {
      type: String,
      default: 'SC01'
    }
  },
  emits: ['getStudent'],
  setup(props, {
    emit
  }) {
    const messageBox = useMessage();
    const refData = reactive({
      storageData: null,
      activeNum: 0,
      checkbox: [{
        name: "SOS号码：",
        default: '',
        val: ''
      }, {
        name: "号码1：",
        default: '',
        val: ''
      }, {
        name: "号码2：",
        default: '',
        val: ''
      }, {
        name: "号码3：",
        default: '',
        val: ''
      }],
      checkList: [],
      activeList: [],
      phone1: '',
      phone2: '',
      phone3: '',
      sosPhone: ''
    });
    watch(() => refData.checkList, val => {
      refData.activeList = refData.checkbox.filter((item, index) => val.includes(index)).map(item => ({
        ...item,
        val: item.default
      }));
    });
    async function sendMsg(params) {
      const arr = refData.checkbox.map(item => item.default); //.filter(item=>item)
      let num = 0;
      refData.activeList.map(item => {
        arr.includes(item.val) && props.isInternet == 1 && props.sendTime && (num += 1);
      });
      if (props.sendTime && props.isInternet == 1 && num === refData.activeList.length) {
        return ElMessageBox.confirm(`温馨提示：本月已在${props.sendTime}修改过一次，本月修改次数已用，请下月一号再重新设置修改`, '提示', {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {}).catch(() => {});
      }
      try {
        if (!refData.sosPhone && !['S341'].includes(props.deviceType)) throw {
          msg: '请输入SOS号码'
        };
        //if(!refData.phone1 && !refData.phone2 && !refData.phone3)throw{msg:'至少输入一个号码'};
        if (refData.sosPhone) {
          if (refData.sosPhone.length > 13) throw {
            msg: 'SOS号码格式错误，请重新输入'
          };
        }
        if (refData.phone1) {
          if (refData.phone1.length > 13) throw {
            msg: '号码1格式错误，请重新输入'
          };
        }
        if (refData.phone2) {
          if (refData.phone2.length > 13) throw {
            msg: '号码2格式错误，请重新输入'
          };
        }
        if (refData.phone3) {
          if (refData.phone3.length > 13) throw {
            msg: '号码3格式错误，请重新输入'
          };
        }
        const commandMsg = {
          phone1: refData.phone1 || '',
          phone2: refData.phone2 || '',
          phone3: refData.phone3 || '',
          sosPhone: refData.sosPhone
        };
        if (refData.storageData && props.isInternet == 1) {
          refData.activeList.map((item, index) => {
            if (item.name === 'SOS号码：') {
              commandMsg.sosPhone = item.val;
            } else if (item.name === '号码1：') {
              commandMsg.phone1 = item.val;
            } else if (item.name === '号码2：') {
              commandMsg.phone2 = item.val;
            } else {
              commandMsg.phone3 = item.val;
            }
          });
        }
        const param = {
          ...params,
          commandMsg: JSON.stringify(commandMsg)
        };
        const result = await api.sendMsg(param);
        messageBox[result.code == 0 ? 'success' : 'error'](`指令下发${result.msg}`);
        result.code == 0 && (emit('getStudent'), getParams());
      } catch ({
        msg
      }) {
        messageBox.warning(msg);
      }
    }
    async function getParams() {
      const {
        data,
        code,
        msg
      } = await api.getFamilyPhone({
        imei: props.imei
      });
      if (data) {
        refData.storageData = data.sosPhone || null;
        refData.phone1 = data.phone1;
        refData.phone2 = data.phone2;
        refData.phone3 = data.phone3;
        refData.sosPhone = data.sosPhone;
        refData.checkbox = refData.checkbox.map((item, index) => {
          return {
            name: item.name,
            default: index ? data[`phone${index}`] ? data[`phone${index}`] : '' : data.sosPhone || '',
            val: index ? data[`phone${index}`] ? data[`phone${index}`] : '' : data.sosPhone || ''
          };
        });
      }
    }
    return {
      ...toRefs(refData),
      sendMsg,
      getParams
    };
  }
});