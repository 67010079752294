import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7b8ed592"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_switch = _resolveComponent("el-switch");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_input = _resolveComponent("el-input");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, null, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 5
    }, {
      default: _withCtx(() => [_createTextVNode("状态：")]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 16
    }, {
      default: _withCtx(() => [_createVNode(_component_el_switch, {
        modelValue: _ctx.onoff,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.onoff = $event),
        "active-color": "#13ce66"
      }, null, 8, ["modelValue"])]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_el_row, null, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 5
    }, {
      default: _withCtx(() => [_createTextVNode("RFID号码：")]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 19
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        type: "text",
        maxlength: "10",
        "show-word-limit": "",
        modelValue: _ctx.rfid,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.rfid = $event)
      }, null, 8, ["modelValue"])]),
      _: 1
    })]),
    _: 1
  })]);
}