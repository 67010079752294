import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  return _openBlock(), _createElementBlock("div", null, [_ctx.cmdType == 1 ? (_openBlock(), _createBlock(_component_el_input, {
    key: 0,
    modelValue: _ctx.cmdcontent,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.cmdcontent = $event),
    modelModifiers: {
      trim: true
    },
    autosize: {
      minRows: 4
    },
    type: "textarea",
    maxlength: "200",
    "show-word-limit": "",
    placeholder: "自定义指令"
  }, null, 8, ["modelValue"])) : _createCommentVNode("", true)]);
}