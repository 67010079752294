import { defineComponent, toRefs, reactive, computed, defineAsyncComponent, watch, nextTick, onMounted } from "vue";
import { useMessage } from "@/hooks/web/useMessage";
export default defineComponent({
  name: "MapVue",
  components: {
    Eldialog: defineAsyncComponent(() => import("@/components/Eldialog/index.vue")),
    BSJMap: defineAsyncComponent(() => import('@/components/BSJMap'))
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "Round"
    }
  },
  emits: ["update:visible", "handleSuccess"],
  setup(props, {
    emit
  }) {
    const message = useMessage();
    const refData = reactive({
      refMap: null,
      draw: null,
      path: null,
      map: null
    });
    // 初始化表单
    const show = computed({
      get: () => props.visible,
      set: val => {
        emit("update:visible", val);
      }
    });
    watch(() => props.visible, val => {}, {
      immediate: true
    });
    //获取地图实例
    function getMap() {
      return [refData.refMap.getMap(), refData.refMap];
    }
    //绘制图形
    const handleDraw = async type => {
      refData.map = getMap()[0];
      refData.map && refData.map.instance.clearOverlays();
      refData.path = null;
      const name = type == "Round" ? "drawCircle" : type == "Rectangle" ? "drawRectangle" : "drawPolygon";
      refData.draw = refData.map[name]({
        strokeColor: "#409EFF",
        strokeWeight: 6,
        strokeOpacity: 0.8 // 折线的透明度，取值范围0 - 1
      }, e => {
        let params = {};
        if (type == 'Rectangle') {
          const {
            Te,
            be,
            Ne,
            ee
          } = e.getBounds();
          params = {
            path: [[be, Te], [ee, Ne]]
          };
          refData.map.closeDraw(refData.draw);
        } else if (type == 'Round') {
          const center = e.getCenter();
          const path = e.getPath();
          const radius = e.getRadius();
          params = {
            center,
            path,
            radius
          };
        } else {
          const path = e.getPath();
          params = {
            path
          };
        }
        refData.path = params;
      });
    };
    //关闭绘画
    const handleClearDraw = () => {
      nextTick(() => {
        var _refData$map$instance;
        refData.map && ((_refData$map$instance = refData.map.instance) === null || _refData$map$instance === void 0 ? void 0 : _refData$map$instance.clearOverlays());
      });
    };
    //关闭弹框
    const confirm = () => {
      if (!refData.path) {
        message.warning('请先绘制图形');
        return false;
      }
      handleClearDraw();
      emit('handleSuccess', refData.path);
      emit("update:visible", false);
    };
    onMounted(() => {});
    return {
      ...toRefs(refData),
      show,
      confirm,
      handleDraw,
      handleClearDraw
    };
  }
});