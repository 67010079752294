import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-8cf673f4"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "wrapper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_col = _resolveComponent("el-col");
  const _component_TypeSelect = _resolveComponent("TypeSelect");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_scrollbar = _resolveComponent("el-scrollbar");
  const _directive_loading = _resolveDirective("loading");
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [!['S341'].includes(_ctx.deviceType) ? (_openBlock(), _createBlock(_component_el_scrollbar, {
    key: 0
  }, {
    default: _withCtx(() => [_createVNode(_component_el_row, null, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 12
      }, {
        default: _withCtx(() => [_createTextVNode("重启终端：")]),
        _: 1
      }), _createVNode(_component_el_col, {
        span: 12
      }, {
        default: _withCtx(() => [_createVNode(_component_TypeSelect, {
          class: "w_100",
          type: "whether",
          modelValue: _ctx.restart,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.restart = $event)
        }, null, 8, ["modelValue"])]),
        _: 1
      })]),
      _: 1
    }), false ? (_openBlock(), _createBlock(_component_el_row, {
      key: 0
    }, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 12
      }, {
        default: _withCtx(() => [_createTextVNode("恢复除平台地址以外的出厂设置：")]),
        _: 1
      }), _createVNode(_component_el_col, {
        span: 12
      }, {
        default: _withCtx(() => [_createVNode(_component_TypeSelect, {
          class: "w_100",
          type: "whether",
          modelValue: _ctx.restore,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.restore = $event)
        }, null, 8, ["modelValue"])]),
        _: 1
      })]),
      _: 1
    })) : _createCommentVNode("", true)]),
    _: 1
  })) : (_openBlock(), _createBlock(_component_el_row, {
    key: 1
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 24
    }, {
      default: _withCtx(() => [_createTextVNode(" 恢复出厂设置无需设置参数，请直接点击“确认按钮”进行下发 ")]),
      _: 1
    })]),
    _: 1
  }))])), [[_directive_loading, _ctx.loading]]);
}