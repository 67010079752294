import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-576cf586"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "wrapper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_input_number = _resolveComponent("el-input-number");
  const _component_el_row = _resolveComponent("el-row");
  const _component_TypeSelect = _resolveComponent("TypeSelect");
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_scrollbar = _resolveComponent("el-scrollbar");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_scrollbar, null, {
    default: _withCtx(() => [_createVNode(_component_el_row, null, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 6
      }, {
        default: _withCtx(() => [_createTextVNode("播报次数：")]),
        _: 1
      }), _createVNode(_component_el_col, {
        span: 15
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input_number, {
          modelValue: _ctx.displayNum,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.displayNum = $event),
          onBlur: _cache[1] || (_cache[1] = () => _ctx.displayNum = parseInt(_ctx.displayNum)),
          min: 1,
          max: 3,
          label: ""
        }, null, 8, ["modelValue"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_row, null, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 6
      }, {
        default: _withCtx(() => [_createTextVNode("闪烁屏幕或LED：")]),
        _: 1
      }), _createVNode(_component_el_col, {
        span: 15
      }, {
        default: _withCtx(() => [_createVNode(_component_TypeSelect, {
          class: "w_100",
          modelValue: _ctx.ledFlag,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.ledFlag = $event),
          type: "openClose"
        }, null, 8, ["modelValue"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_row, null, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 6
      }, {
        default: _withCtx(() => [_createTextVNode("震动设置：")]),
        _: 1
      }), _createVNode(_component_el_col, {
        span: 15
      }, {
        default: _withCtx(() => [_createVNode(_component_TypeSelect, {
          class: "w_100",
          modelValue: _ctx.vibration,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.vibration = $event),
          type: "openClose"
        }, null, 8, ["modelValue"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_row, null, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 6
      }, {
        default: _withCtx(() => [_createTextVNode("响铃设置：")]),
        _: 1
      }), _createVNode(_component_el_col, {
        span: 15
      }, {
        default: _withCtx(() => [_createVNode(_component_TypeSelect, {
          class: "w_100",
          modelValue: _ctx.sound,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.sound = $event),
          type: "openClose"
        }, null, 8, ["modelValue"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_row, null, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 6
      }, {
        default: _withCtx(() => [_createTextVNode("短信类型：")]),
        _: 1
      }), _createVNode(_component_el_col, {
        span: 15
      }, {
        default: _withCtx(() => [_createVNode(_component_TypeSelect, {
          class: "w_100",
          type: "smsType",
          modelValue: _ctx.smsType,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _ctx.smsType = $event)
        }, null, 8, ["modelValue"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_row, null, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 6
      }, {
        default: _withCtx(() => [_createTextVNode("显示类型：")]),
        _: 1
      }), _createVNode(_component_el_col, {
        span: 15
      }, {
        default: _withCtx(() => [_createVNode(_component_TypeSelect, {
          class: "w_100",
          type: "displayType",
          modelValue: _ctx.displayType,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _ctx.displayType = $event)
        }, null, 8, ["modelValue"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_row, null, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 6
      }, {
        default: _withCtx(() => [_createTextVNode("消息内容：")]),
        _: 1
      }), _createVNode(_component_el_col, {
        span: 15
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          class: "w_100",
          type: "textarea",
          modelValue: _ctx.context,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => _ctx.context = $event),
          maxlength: "200",
          "show-word-limit": "",
          rows: 4
        }, null, 8, ["modelValue"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })]);
}