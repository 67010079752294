import "core-js/modules/es.array.push.js";
import api from '@/api/instruction';
import { useMessage } from '@/hooks/web/useMessage';
import { ElCol, ElInput, ElRow, ElScrollbar, ElSwitch, ElTimeSelect, ElTimePicker } from 'element-plus';
import { defineComponent, reactive, toRefs, defineAsyncComponent } from 'vue';
import { useDebounce } from '@/hooks/core/useDebounce';
import dayjs from 'dayjs';
export default defineComponent({
  name: 'SetAnAlarm',
  components: {
    ElRow,
    ElCol,
    ElInput,
    ElScrollbar,
    ElSwitch,
    ElTimeSelect,
    ElTimePicker,
    TypeSelect: defineAsyncComponent(() => import('@/components/TypeSelect/TypeSelect.vue'))
  },
  props: {
    imei: {
      type: String
    }
  },
  setup(props, {
    emit
  }) {
    const messageBox = useMessage();
    const refData = reactive({
      loading: false,
      list: []
    });
    async function sendMsg(params) {
      try {
        if (!refData.list.length) throw {
          msg: '请添加时间'
        };
        if (refData.list.some(item => !item.weeks.length)) throw {
          msg: '请完善时间信息'
        };
        const list = [];
        refData.list.map((item, index) => {
          list.push(`${index + 1}=${dayjs(item.time).format('HHmm')}!${item.weeks.length ? item.weeks.join('+') : 0}!${item.status ? 0 : 1}`);
        });
        const param = {
          ...params,
          commandMsg: JSON.stringify({
            clockList: list
          })
        };
        const result = await api.sendMsg(param);
        messageBox[result.code == 0 ? 'success' : 'error'](`指令下发${result.msg}`);
      } catch ({
        msg
      }) {
        messageBox.warning(msg);
      }
    }
    //删除
    const handleDel = active => {
      refData.list = refData.list.filter((item, index) => active != index);
    };
    //添加
    const handleAdd = () => {
      refData.list.push({
        time: new Date(dayjs().format('YYYY-MM-DD 00:00')),
        status: true,
        weeks: []
      });
    };
    async function getParams() {
      refData.loading = false;
      const {
        data,
        code,
        msg
      } = await api.getCardCommandConf({
        mdtid: props.imei,
        commandType: 17
      });
      if (data && code == 0) {
        const params = JSON.parse(data.data);
        refData.list = params.clockList ? params.clockList.map(item => ({
          weeks: [...item.split('!')[1].split('+')].map(item => Number(item)),
          time: new Date(dayjs().format(`YYYY-MM-DD ${item.substring(2, 4)}:${item.substring(4, 6)}`)),
          status: item.substring(item.length - 1) == '1' ? false : true,
          aaa: `${item.substring(2, 4)}:${item.substring(4, 6)}`
        })) : handleAdd();
      } else {
        handleAdd();
      }
      refData.loading = false;
    }
    const [debounceHandleDel] = useDebounce(handleDel, 200);
    const [debounceHandleAdd] = useDebounce(handleAdd, 200);
    return {
      ...toRefs(refData),
      sendMsg,
      debounceHandleDel,
      debounceHandleAdd,
      getParams
    };
  }
});