import api from '@/api/instruction';
import { useMessage } from '@/hooks/web/useMessage';
import { ElCol, ElInput, ElRow, ElScrollbar, ElSwitch } from 'element-plus';
import { defineComponent, reactive, toRefs, defineAsyncComponent } from 'vue';
export default defineComponent({
  name: 'SceneMode',
  components: {
    ElRow,
    ElCol,
    ElInput,
    ElScrollbar,
    ElSwitch,
    TypeSelect: defineAsyncComponent(() => import('@/components/TypeSelect/TypeSelect.vue'))
  },
  props: {
    imei: {
      type: String
    }
  },
  setup(props, {
    emit
  }) {
    const messageBox = useMessage();
    const refData = reactive({
      one: true,
      two: true,
      callIn: true,
      callOut: true,
      loading: false
    });
    async function sendMsg(params) {
      try {
        const param = {
          ...params,
          commandMsg: JSON.stringify({
            one: refData.one ? 1 : 0,
            two: refData.two ? 1 : 0,
            callIn: refData.callIn ? 1 : 0,
            callOut: refData.callOut ? 1 : 0
          })
        };
        const result = await api.sendMsg(param);
        messageBox[result.code == 0 ? 'success' : 'error'](`指令下发${result.msg}`);
      } catch ({
        msg
      }) {
        messageBox.warning(msg);
      }
    }
    async function getParams() {
      refData.loading = true;
      const {
        data,
        code,
        msg
      } = await api.getCardCommandConf({
        mdtid: props.imei,
        commandType: 11
      });
      if (code == 0 && data) {
        const params = JSON.parse(data.data);
        refData.one = params.one ? true : false;
        refData.two = params.two ? true : false;
        refData.callIn = params.callIn ? true : false;
        refData.callOut = params.callOut ? true : false;
      }
      refData.loading = false;
    }
    return {
      ...toRefs(refData),
      sendMsg,
      getParams
    };
  }
});