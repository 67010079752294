import api from '@/api/instruction';
import { useMessage } from '@/hooks/web/useMessage';
import { ElCol, ElInput, ElRow } from 'element-plus';
import { defineComponent, reactive, toRefs } from 'vue';
export default defineComponent({
  name: 'Penetrate',
  components: {
    ElRow,
    ElCol,
    ElInput
  },
  props: {
    imei: {
      type: String
    }
  },
  emits: ['getStudent'],
  setup(props, {
    emit
  }) {
    const messageBox = useMessage();
    const refData = reactive({
      phone1: null,
      phone2: null,
      phone3: null,
      name1: '',
      name2: '',
      name3: ''
    });
    async function sendMsg(params) {
      const refPhone = /^1[3456789]\d{9}$/;
      try {
        if (!refData.phone1 && !refData.name1) throw {
          msg: '请完善第一条数据'
        };
        if (!refData.phone2 && !refData.name2) throw {
          msg: '请完善第二条数据'
        };
        if (!refData.phone3 && !refData.name3) throw {
          msg: '请完善第三条数据'
        };
        const commandMsg = {
          phone1: refData.phone1,
          phone2: refData.phone2,
          phone3: refData.phone3,
          familyName1: refData.name1,
          familyName2: refData.name2,
          familyName3: refData.name3
        };
        const param = {
          ...params,
          commandMsg: JSON.stringify(commandMsg)
        };
        const result = await api.sendMsg(param);
        messageBox[result.code == 0 ? 'success' : 'error'](`指令下发${result.msg}`);
        result.code == 0 && (emit('getStudent'), getParams());
      } catch ({
        msg
      }) {
        messageBox.warning(msg);
      }
    }
    async function getParams() {
      const {
        data,
        code,
        msg
      } = await api.getCardCommandConf({
        mdtid: props.imei,
        commandType: 26
      });
      if (data) {
        const mydata = JSON.parse(data.data);
        refData.phone1 = mydata.phone1;
        refData.phone2 = mydata.phone2;
        refData.phone3 = mydata.phone3;
        refData.name1 = mydata.familyName1;
        refData.name2 = mydata.familyName2;
        refData.name3 = mydata.familyName3;
      }
    }
    return {
      ...toRefs(refData),
      sendMsg,
      getParams
    };
  }
});