import "core-js/modules/es.array.push.js";
import api from '@/api/instruction';
import { useMessage } from '@/hooks/web/useMessage';
import { defineComponent, reactive, toRefs } from 'vue';
export default defineComponent({
  name: 'SOS',
  props: {
    sosNumber: {
      type: [String, Number],
      default: ''
    },
    imei: {
      type: String
    }
  },
  emits: ['getStudent'],
  setup(props, {
    emit
  }) {
    const messageBox = useMessage();
    const refData = reactive({
      sosNumber: '',
      onoff: false,
      checkbox: [{
        default: '',
        val: '不设置',
        label: '不设置'
      }]
    });
    // 下发
    async function sendMsg(params) {
      try {
        if (!refData.sosNumber) throw {
          msg: '请输入SOS号码'
        };
        const result = await api.sendSOSPhoneMsg({
          sosNumber: refData.sosNumber === '不设置' ? '' : refData.sosNumber,
          ...params
        });
        messageBox[result.code == 0 ? 'success' : 'error'](`指令下发${result.msg}`);
        result.code === 0 && emit('getStudent');
      } catch ({
        msg
      }) {
        messageBox.warning(msg);
      }
    }
    // 获取复现参数
    async function getParams() {
      const {
        data,
        code,
        msg
      } = await api.getSOSPhone({
        imei: props.imei
      });
      if (data) {
        refData.sosNumber = `${data.sosNumber}`;
      }
      getList();
    }
    // 获取选项
    async function getList() {
      const {
        data,
        code,
        msg
      } = await api.getFamilyPhone({
        imei: props.imei
      });
      refData.onoff = true;
      if (data) {
        Object.keys(data).map((key, index) => {
          ['phone1', 'phone2', 'phone3'].includes(key) && data[key] && !refData.checkbox.includes(data[key]) && refData.checkbox.push({
            default: data[key],
            val: `${data[key]}`,
            label: `${data[key]}`
          });
        });
      }
    }
    return {
      ...toRefs(refData),
      sendMsg,
      getParams
    };
  }
});