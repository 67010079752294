import api from '@/api/instruction';
import { useMessage } from '@/hooks/web/useMessage';
import { ElInput, ElRow } from 'element-plus';
import { defineComponent, reactive, toRefs } from 'vue';
export default defineComponent({
  name: 'Textdown',
  components: {
    ElRow,
    ElInput
  },
  props: {
    cmdType: Number
  },
  setup(props, {
    emit
  }) {
    const messageBox = useMessage();
    const refData = reactive({
      cmddesc: '',
      cmdcontent: ''
    });
    async function sendMsg(params) {
      const result = await api.sendMsg({
        ...refData,
        ...params
      });
      console.log(result);
      messageBox[result.code == 0 ? 'success' : 'error'](`指令下发${result.msg}`);
    }
    return {
      ...toRefs(refData),
      sendMsg
    };
  }
});