import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-00f4c36a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "wrapper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_switch = _resolveComponent("el-switch");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_scrollbar = _resolveComponent("el-scrollbar");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_scrollbar, null, {
    default: _withCtx(() => [_createVNode(_component_el_row, null, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 4
      }, {
        default: _withCtx(() => [_createTextVNode("静音：")]),
        _: 1
      }), _createVNode(_component_el_col, {
        span: 15,
        offset: 1
      }, {
        default: _withCtx(() => [_createVNode(_component_el_switch, {
          modelValue: _ctx.one,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.one = $event),
          "active-color": "#13ce66"
        }, null, 8, ["modelValue"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_row, null, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 4
      }, {
        default: _withCtx(() => [_createTextVNode("响铃：")]),
        _: 1
      }), _createVNode(_component_el_col, {
        span: 15,
        offset: 1
      }, {
        default: _withCtx(() => [_createVNode(_component_el_switch, {
          modelValue: _ctx.two,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.two = $event),
          "active-color": "#13ce66"
        }, null, 8, ["modelValue"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_row, null, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 4
      }, {
        default: _withCtx(() => [_createTextVNode("限制呼入：")]),
        _: 1
      }), _createVNode(_component_el_col, {
        span: 15,
        offset: 1
      }, {
        default: _withCtx(() => [_createVNode(_component_el_switch, {
          modelValue: _ctx.callIn,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.callIn = $event),
          "active-color": "#13ce66"
        }, null, 8, ["modelValue"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_row, null, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 4
      }, {
        default: _withCtx(() => [_createTextVNode("限制呼出：")]),
        _: 1
      }), _createVNode(_component_el_col, {
        span: 15,
        offset: 1
      }, {
        default: _withCtx(() => [_createVNode(_component_el_switch, {
          modelValue: _ctx.callOut,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.callOut = $event),
          "active-color": "#13ce66"
        }, null, 8, ["modelValue"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })]);
}