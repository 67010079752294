import { ElMessageBox } from "element-plus";
import router from "@/router/index";
//判断当前员工证是否过期
export function handleISExpired (expireDate:string = "") {
  ElMessageBox.confirm(`该员工证${expireDate}已过期，无法正常使用，请续费后使用`,'提示' , {
    confirmButtonText: "前往续费",
    cancelButtonText:"关闭",
    type: "warning",
  }).then(() => {
    router.push('/authority/ServiceRenewal');
  }).catch(()=>{
  })
}