import api from '@/api/instruction';
import { useMessage } from '@/hooks/web/useMessage';
import { ElCol, ElInput, ElRow, ElScrollbar, ElSwitch, ElInputNumber } from 'element-plus';
import { defineComponent, reactive, toRefs, defineAsyncComponent } from 'vue';
export default defineComponent({
  name: 'SMSScenario',
  components: {
    ElRow,
    ElCol,
    ElInput,
    ElScrollbar,
    ElSwitch,
    ElInputNumber,
    TypeSelect: defineAsyncComponent(() => import('@/components/TypeSelect/TypeSelect.vue'))
  },
  props: {
    imei: {
      type: String
    }
  },
  setup(props, {
    emit
  }) {
    const messageBox = useMessage();
    const refData = reactive({
      smsType: 0,
      displayType: 0,
      displayNum: 1,
      ledFlag: 1,
      vibration: 0,
      sound: 1,
      context: '',
      loading: false
    });
    async function sendMsg(params) {
      try {
        if (!refData.displayNum) throw {
          msg: '请输入播报次数'
        };
        const param = {
          ...params,
          commandMsg: JSON.stringify({
            smsType: refData.smsType,
            displayType: refData.displayType,
            displayNum: refData.displayNum,
            ledFlag: refData.ledFlag,
            vibration: refData.vibration,
            sound: refData.sound,
            context: refData.context
          })
        };
        const result = await api.sendMsg(param);
        messageBox[result.code == 0 ? 'success' : 'error'](`指令下发${result.msg}`);
      } catch ({
        msg
      }) {
        messageBox.warning(msg);
      }
    }
    async function getParams() {
      refData.loading = true;
      const {
        data,
        code,
        msg
      } = await api.getCardCommandConf({
        mdtid: props.imei,
        commandType: 14
      });
      if (code == 0 && data) {
        const params = JSON.parse(data.data);
        refData.smsType = params.smsType;
        refData.displayType = params.displayType;
        refData.displayNum = params.displayNum;
        refData.ledFlag = params.ledFlag;
        refData.vibration = params.vibration;
        refData.sound = params.sound;
        refData.context = params.context;
      }
      refData.loading = false;
    }
    return {
      ...toRefs(refData),
      sendMsg,
      getParams
    };
  }
});