import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_switch = _resolveComponent("el-switch");
  const _component_el_row = _resolveComponent("el-row");
  const _component_UploadType = _resolveComponent("UploadType");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_row, null, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 5
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.noticeLabel), 1)]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 19
    }, {
      default: _withCtx(() => [_createVNode(_component_el_switch, {
        modelValue: _ctx.isRemind,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.isRemind = $event),
        "active-value": 1,
        "inactive-value": 0
      }, null, 8, ["modelValue"])]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_el_row, null, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 5
    }, {
      default: _withCtx(() => [_createTextVNode("上报方式")]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 19
    }, {
      default: _withCtx(() => [_createVNode(_component_UploadType, {
        modelValue: _ctx.reportType,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.reportType = $event)
      }, null, 8, ["modelValue"])]),
      _: 1
    })]),
    _: 1
  })]);
}