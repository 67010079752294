import { defineComponent, toRefs, reactive, computed, watch, nextTick } from 'vue';
import Eldialog from '@/components/Eldialog/index.vue';
import { commandTypeOptions, commandBox } from './data';
import { isNullOrUnDef } from '@/utils/is';
import { handleISExpired } from '@/utils';
import api from '@/api/studentCard';
import { useMessage } from '@/hooks/web/useMessage';
import TextTTS from './TextTTS.vue';
import TransData from './TransData.vue';
import IPSetting from './IPSetting.vue';
import Notice from './Notice.vue';
import FamilyNumber from './FamilyNumber.vue';
import SOS from './SOS.vue';
import Whitelist from './Whitelist.vue';
import Textdown from './Textdown.vue';
import SetBother from './SetBother.vue';
import ServiceInfo from './ServiceInfo.vue';
import SetTime from './SetTime.vue';
import SceneMode from './SceneMode.vue';
import SMSScenario from './SMSScenario.vue';
import RemoteTerminal from './RemoteTerminal.vue';
import PositionMode from './PositionMode.vue';
import StandbyMode from './StandbyMode.vue';
import SetAnAlarm from './SetAnAlarm.vue';
import CallDuration from './CallDuration.vue';
import WifiMac from './WifiMac.vue';
import AlarmArea from './AlarmArea.vue';
import RfidVue from './RFID.vue';
import ActivationGPS from './ActivationGPS.vue';
import Penetrate from './Penetrate.vue';
import { useRouter } from "vue-router";
export default defineComponent({
  name: 'InstructionDialog',
  components: {
    Eldialog,
    Notice,
    IPSetting,
    TextTTS,
    TransData,
    FamilyNumber,
    SOS,
    Whitelist,
    Textdown,
    SetBother,
    ServiceInfo,
    SetTime,
    SceneMode,
    SMSScenario,
    RemoteTerminal,
    PositionMode,
    StandbyMode,
    SetAnAlarm,
    CallDuration,
    WifiMac,
    AlarmArea,
    RfidVue,
    ActivationGPS,
    Penetrate
  },
  emits: ['update:visible'],
  props: {
    objectids: {
      type: String,
      default: ''
    },
    batchSend: {
      type: Boolean,
      default: false
    },
    objectid: {
      type: Number,
      default: 0
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {
    emit
  }) {
    const router = useRouter();
    const messageBox = useMessage();
    const refData = reactive({
      prompt: '',
      refCmd: null,
      title: '指令下发',
      cmdType: null,
      mdtid: '',
      deviceType: '',
      isInternet: 1,
      sendTime: ''
    });
    const show = computed({
      get: () => props.visible,
      set: val => {
        emit('update:visible', val);
      }
    });
    const isBatchSend = computed(() => {
      return props.batchSend;
    });
    const prompt = computed(() => {
      return commandBox.filter(item => item.value == refData.cmdType).length ? commandBox.filter(item => item.value == refData.cmdType)[0][refData.isInternet == 1 ? 'vCard' : refData.deviceType === 'S341' ? 'S341' : 'label'] : '';
    });
    watch(() => props.visible, visible => {
      refData.cmdType = visible ? 2 : null;
      if (!visible) return;
      if (!props.objectid) return;
      getStudent();
    });
    watch(() => refData.cmdType, val => {
      nextTick(() => {
        setTimeout(() => {
          [3, 4, 26].includes(refData.cmdType) && refData.refCmd && refData.refCmd.getParams();
        }, 600);
      });
    });
    //获取员工
    async function getStudent() {
      try {
        if (props.batchSend) {
          //批量发送
        } else {
          if (props.objectid <= 0) return;
          const result = await api.getByIdObjectinfo({
            id: props.objectid
          });
          if (result.code !== 0) {
            messageBox.warning(result.msg);
            return;
          }
          const {
            deviceType,
            mdtid,
            familySos,
            expireDate,
            expireStatus
          } = result.data;
          if (expireStatus === 1) {
            handleISExpired(expireDate);
            emit('update:visible', false);
          }
          console.log("getStudent" + refData.deviceType);
          refData.deviceType = deviceType;
          if (deviceType == null) {
            refData.deviceType = "CAR";
          }
          //refData.isInternet = isInternet;
          //refData.sendTime = sendTime;
          refData.mdtid = mdtid;
          // nextTick(()=>{
          //   refData.refCmd && refData.refCmd.getParams();
          // })
        }
      } catch (error) {
        console.log(error);
      }
    }
    async function confirm() {
      try {
        if (props.batchSend) {
          //批量发送
          if (props.objectids != "" || isNullOrUnDef(refData.refCmd)) return;
          const result = await refData.refCmd.sendMsg({
            cmdtype: refData.cmdType,
            mdtid: refData.mdtid,
            objectid: props.objectid
          });
          if (result !== true) return;
        } else {
          if (props.objectid <= 0 || isNullOrUnDef(refData.refCmd)) return;
          const result = await refData.refCmd.sendMsg({
            cmdtype: refData.cmdType,
            mdtid: refData.mdtid,
            objectid: props.objectid
          });
          if (result !== true) return;
        }
      } catch (error) {
        console.error(error);
      }
      show.value = false;
    }
    function noticeLabel(cmdType) {
      if (cmdType === 3) return '开机提醒';
      if (cmdType === 4) return '关机提醒';
      if (cmdType === 5) return '低电量提醒';
    }
    //指令选项判断
    function handleVisible(cmd) {
      console.log("handleVisible" + refData.deviceType);
      if (refData.deviceType == 'S341') {
        if (refData.isInternet === 1) {
          return cmd.value === 2 ? false : cmd.visible.includes(refData.deviceType);
        } else {
          return cmd.visible.includes(refData.deviceType);
        }
      } else {
        return cmd.visible.includes(refData.deviceType);
      }
    }
    return {
      ...toRefs(refData),
      commandTypeOptions,
      show,
      isBatchSend,
      confirm,
      noticeLabel,
      prompt,
      handleVisible,
      getStudent
    };
  }
});