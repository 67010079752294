import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_input_number = _resolveComponent("el-input-number");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_row, null, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 5
    }, {
      default: _withCtx(() => [_createTextVNode("IP地址：")]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 15,
      offset: 1
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: _ctx.ipaddress,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.ipaddress = $event),
        modelModifiers: {
          trim: true
        },
        type: "text",
        maxlength: "50",
        placeholder: "IP地址域名设置"
      }, null, 8, ["modelValue"])]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_el_row, null, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 5
    }, {
      default: _withCtx(() => [_createTextVNode("端口：")]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 15,
      offset: 1
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input_number, {
        modelValue: _ctx.port,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.port = $event),
        min: 1000,
        max: 65535,
        onBlur: _cache[2] || (_cache[2] = val => _ctx.port = parseInt(_ctx.port)),
        label: ""
      }, null, 8, ["modelValue"])]),
      _: 1
    })]),
    _: 1
  })]);
}