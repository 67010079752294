import "core-js/modules/es.array.push.js";
import api from '@/api/instruction';
import { useMessage } from '@/hooks/web/useMessage';
import { ElCol, ElInput, ElRow, ElScrollbar, ElSwitch, ElTimeSelect, ElTimePicker, ElSpace } from 'element-plus';
import { defineComponent, reactive, toRefs, defineAsyncComponent, computed, unref } from 'vue';
import dayjs from 'dayjs';
export default defineComponent({
  name: 'FamilyNumber',
  components: {
    ElRow,
    ElCol,
    ElInput,
    ElScrollbar,
    ElSwitch,
    ElTimePicker,
    ElTimeSelect,
    ElSpace,
    TypeSelect: defineAsyncComponent(() => import('@/components/TypeSelect/TypeSelect.vue')),
    MapVue: defineAsyncComponent(() => import('./MapVue.vue'))
  },
  props: {
    imei: {
      type: String
    }
  },
  setup(props, {
    emit
  }) {
    const messageBox = useMessage();
    const refData = reactive({
      mapVisible: false,
      operationCode: 1,
      requestState: 1,
      shape: '',
      dataList: [],
      enclosureNo: '',
      cycle: [],
      radius: 0,
      loading: false,
      // 时间段1
      timeRange1: [dayjs(dayjs().format('YYYY-MM-DD 00:00')), dayjs(dayjs().format('YYYY-MM-DD 23:59'))],
      // 时间段2
      timeRange2: [dayjs(dayjs().format('YYYY-MM-DD 00:00')), dayjs(dayjs().format('YYYY-MM-DD 23:59'))]
    });
    // 时间段1
    const timeStr1 = computed(() => refData.timeRange1.map(t => dayjs(t).format('HHmm')).join('-'));
    // 时间段2
    const timeStr2 = computed(() => refData.timeRange2.map(t => dayjs(t).format('HHmm')).join('-'));
    const timeStr = computed(() => [unref(timeStr1), unref(timeStr2)].join('+'));
    async function sendMsg(params) {
      try {
        if (!refData.dataList.length) throw {
          msg: '请选择图形，进行绘制'
        };
        if (!refData.enclosureNo) throw {
          msg: '请填写区域编号'
        };
        if (!refData.cycle.length) throw {
          msg: '请选择周期'
        };
        const param = {
          ...params,
          cmdcontent: JSON.stringify({
            operationCode: refData.operationCode,
            requestState: refData.requestState,
            shape: refData.shape,
            dataList: refData.shape == 'Round' ? [...refData.dataList, refData.radius] : refData.dataList,
            enclosureNo: refData.enclosureNo,
            // HHmm-HHmm+HHmm-HHmm
            timeStr: unref(timeStr),
            cycle: refData.cycle.join('+')
          })
        };
        console.log(params);
        const result = await api.sendMsg(param);
        messageBox[result.code == 0 ? 'success' : 'error'](`指令下发${result.msg}`);
      } catch ({
        msg
      }) {
        messageBox.warning(msg);
      }
    }
    //地图回调
    const handleSuccess = data => {
      let point = '';
      refData.radius = 0;
      refData.dataList = [];
      if (!data) return;
      if (refData.shape == 'Round') {
        const {
          lat,
          lng
        } = data.center;
        refData.dataList.push(`${lat}#${lng}`);
        refData.radius = data.radius;
      } else if (refData.shape == 'Rectangle') {
        data.path.map(item => {
          refData.dataList.push(`${item[0]}#${item[1]}`);
        });
      } else {
        if (data.path.length > 8) {
          messageBox.warning(`多边形绘制最多绘制8个点，您已绘制${data.path.length}，请重新绘制`);
          return false;
        }
        data.path.map(item => {
          const {
            lat,
            lng
          } = item;
          refData.dataList.push(`${lat}#${lng}`);
        });
      }
    };
    async function getParams() {
      try {
        refData.loading = true;
        const {
          data,
          code,
          msg
        } = await api.getCardCommandConf({
          mdtid: props.imei,
          commandType: 23
        });
        if (code == 0 && data) {
          const params = JSON.parse(data.data);
          // [[HHmm,HHmm],[HHmm,HHmm]]
          const times = params.timeStr.split('+').map(item => item.split('-'));
          refData.timeRange1 = times[0].map(t => dayjs(dayjs().format(`YYYY-MM-DD ${t}`)));
          refData.timeRange2 = times[1].map(t => dayjs(dayjs().format(`YYYY-MM-DD ${t}`)));
          refData.operationCode = params.operationCode;
          refData.requestState = params.requestState;
          refData.shape = params.shape;
          refData.enclosureNo = params.enclosureNo;
          refData.cycle = params.cycle.split('+').map(item => Number(item));
          if (params.shape == 'Round') {
            refData.dataList = params.dataList.filter((item, index) => index != params.dataList.length);
          } else {
            refData.dataList = params.dataList;
          }
        }
        refData.loading = false;
      } catch (err) {
        console.log(err);
      } finally {
        refData.loading = false;
      }
    }
    return {
      ...toRefs(refData),
      sendMsg,
      handleSuccess,
      getParams
    };
  }
});