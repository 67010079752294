import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6bcf3809"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "wrapper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_scrollbar = _resolveComponent("el-scrollbar");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_scrollbar, null, {
    default: _withCtx(() => [_createVNode(_component_el_row, null, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 3
      }, {
        default: _withCtx(() => [_createTextVNode("地址：")]),
        _: 1
      }), _createVNode(_component_el_col, {
        span: 18,
        offset: 1
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          class: "input-with-select",
          modelValue: _ctx.url,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.url = $event),
          modelModifiers: {
            trim: true,
            number: true
          },
          placeholder: "请输入地址"
        }, {
          prepend: _withCtx(() => [_createVNode(_component_el_select, {
            modelValue: _ctx.type,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.type = $event),
            placeholder: "请选择"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_option, {
              label: "域名",
              value: 1
            }), _createVNode(_component_el_option, {
              label: "IP地址",
              value: 2
            })]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        }, 8, ["modelValue"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_row, null, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 3
      }, {
        default: _withCtx(() => [_createTextVNode("端口：")]),
        _: 1
      }), _createVNode(_component_el_col, {
        span: 18,
        offset: 1
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: _ctx.port,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.port = $event),
          modelModifiers: {
            trim: true,
            number: true
          },
          placeholder: "请输入端口"
        }, null, 8, ["modelValue"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })]);
}