import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-248ec32a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "wrapper"
};
const _hoisted_2 = {
  key: 0,
  class: "color"
};
const _hoisted_3 = {
  key: 1,
  class: "color"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_col = _resolveComponent("el-col");
  const _component_TypeSelect = _resolveComponent("TypeSelect");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_input_number = _resolveComponent("el-input-number");
  const _component_el_scrollbar = _resolveComponent("el-scrollbar");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_scrollbar, null, {
    default: _withCtx(() => [_createVNode(_component_el_row, null, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 5
      }, {
        default: _withCtx(() => [_createTextVNode("工作模式：")]),
        _: 1
      }), _createVNode(_component_el_col, {
        span: 15,
        offset: 1
      }, {
        default: _withCtx(() => [_createVNode(_component_TypeSelect, {
          type: "modeType",
          modelValue: _ctx.modeType,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.modeType = $event),
          style: {
            "width": "100%"
          }
        }, null, 8, ["modelValue"]), _ctx.modeType == 1 ? (_openBlock(), _createElementBlock("p", _hoisted_2, " 省电模式:省电模式下设备可主动定位，保持正常心跳。设备不主动上报位置。 ")) : _createCommentVNode("", true), _ctx.modeType == 2 ? (_openBlock(), _createElementBlock("p", _hoisted_3, " 平衡模式:设备可主动定位，保持正常心跳。设备每5分钟为基准，上报一次当前位置 ")) : _createCommentVNode("", true)]),
        _: 1
      })]),
      _: 1
    }), _withDirectives(_createVNode(_component_el_row, null, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 5
      }, {
        default: _withCtx(() => [_createTextVNode("工作时长(秒)：")]),
        _: 1
      }), _createVNode(_component_el_col, {
        span: 15,
        offset: 1
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input_number, {
          disabled: _ctx.modeType != 3,
          modelValue: _ctx.min,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.min = $event),
          min: 5,
          max: 999,
          onBlur: _cache[2] || (_cache[2] = val => _ctx.min = parseInt(_ctx.min)),
          label: ""
        }, null, 8, ["disabled", "modelValue"])]),
        _: 1
      })]),
      _: 1
    }, 512), [[_vShow, _ctx.modeType == 3]])]),
    _: 1
  })]);
}