import { defineComponent, toRefs, reactive, watch } from 'vue';
import { ElCol, ElRow, ElSwitch } from "element-plus";
import Eldialog from '@/components/Eldialog/index.vue';
import api from '@/api/instruction';
import API from '@/api/instruction';
import { useMessage } from '@/hooks/web/useMessage';
export default defineComponent({
  name: "RFID",
  components: {
    Eldialog,
    ElCol,
    ElRow,
    ElSwitch
  },
  props: {
    imei: {
      type: String
    }
  },
  setup(props) {
    const message = useMessage();
    const refData = reactive({
      onoff: false,
      rfid: null,
      visible: false
    });
    watch(() => refData.visible, val => {
      val && (refData.rfid = null);
    });
    //参数验证
    const paramsVerify = () => {
      let str = refData.rfid;
      for (let i = 10 - refData.rfid.length; i > 0; i--) {
        str = '0' + str;
      }
      ;
      return str;
    };
    //确定按钮
    const sendMsg = async param => {
      try {
        const reg = /^[0-9]*$/;
        if (!refData.rfid) throw {
          msg: 'RFID号码不能为空'
        };
        if (!reg.test(refData.rfid)) throw {
          msg: 'RFID号码只能输入数字'
        };
        const params = {
          ...param,
          commandMsg: JSON.stringify({
            rfid: refData.rfid.length < 8 ? paramsVerify() : refData.rfid,
            onOrOff: refData.onoff
          })
        };
        const {
          code,
          msg
        } = await API.sendMsg(params);
        message[code == 0 ? 'success' : 'warning'](code == 0 ? '下发成功' : msg);
        code == 0 && (refData.visible = false);
      } catch ({
        msg
      }) {
        message.warning(msg);
      }
    };
    async function getParams() {
      try {
        const {
          data,
          code,
          msg
        } = await api.getCardCommandConf({
          mdtid: props.imei,
          commandType: 24
        });
        if (data.data) {
          const params = JSON.parse(data.data);
          refData.rfid = params.rfid;
          refData.onoff = params.onOrOff;
        }
      } catch (err) {
        console.log(err);
      }
    }
    return {
      ...toRefs(refData),
      sendMsg,
      getParams
    };
  }
});