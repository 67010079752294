import api from '@/api/instruction';
import { useMessage } from '@/hooks/web/useMessage';
import { ElCol, ElInput, ElRow } from 'element-plus';
import { defineComponent, reactive, toRefs, defineAsyncComponent } from 'vue';
export default defineComponent({
  name: 'IPSetting',
  components: {
    ElRow,
    ElInput,
    ElCol,
    TypeSelect: defineAsyncComponent(() => import('@/components/TypeSelect/TypeSelect.vue'))
  },
  props: {
    mdtid: {
      type: String
    }
  },
  setup(props, {
    emit
  }) {
    const messageBox = useMessage();
    const refData = reactive({
      ipaddress: '',
      port: 6808,
      loading: false
    });
    async function sendMsg(params) {
      const param = {
        ...params,
        cmdtype: 8,
        cmddesc: refData.ipaddress + "," + refData.port,
        cmdcontent: JSON.stringify({
          param_ip: refData.ipaddress,
          param_port: refData.port
        })
      };
      const result = await api.sendMsg(param);
      console.log(result);
      messageBox[result.code == 0 ? 'success' : 'error'](`指令下发${result.msg}`);
    }
    async function getParams() {
      refData.loading = true;
      const {
        data,
        code,
        msg
      } = await api.getCardCommandConf({
        mdtid: props.mdtid,
        commandType: 8
      });
      if (code == 0 && data) {
        const params = JSON.parse(data.data);
        refData.ipaddress = params.ipaddress;
        refData.port = params.port;
      }
      refData.loading = false;
    }
    return {
      ...toRefs(refData),
      getParams,
      sendMsg
    };
  }
});