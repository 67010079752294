import api from '@/api/instruction';
import { useMessage } from '@/hooks/web/useMessage';
import { ElCol, ElInput, ElRow, ElScrollbar, ElRadioGroup, ElRadio } from 'element-plus';
import { defineComponent, reactive, toRefs, defineAsyncComponent } from 'vue';
export default defineComponent({
  name: 'RemoteTerminal',
  components: {
    ElRow,
    ElCol,
    ElInput,
    ElScrollbar,
    ElRadioGroup,
    ElRadio,
    TypeSelect: defineAsyncComponent(() => import('@/components/TypeSelect/TypeSelect.vue'))
  },
  props: {
    imei: {
      type: String
    },
    //设备类型
    deviceType: {
      type: String,
      default: 'SC01'
    }
  },
  setup(props, {
    emit
  }) {
    const messageBox = useMessage();
    const refData = reactive({
      restart: 0,
      restore: 0,
      loading: false
    });
    async function sendMsg(params) {
      try {
        const commandMsg = JSON.stringify(['S341'].includes(props.deviceType) ? {} : {
          restart: refData.restart,
          restore: refData.restore
        });
        const param = {
          ...params,
          commandMsg
        };
        const result = await api.sendMsg(param);
        messageBox[result.code == 0 ? 'success' : 'error'](`指令下发${result.msg}`);
      } catch ({
        msg
      }) {
        messageBox.warning(msg);
      }
    }
    async function getParams() {
      refData.loading = true;
      const {
        data,
        code,
        msg
      } = await api.getCardCommandConf({
        mdtid: props.imei,
        commandType: 9
      });
      if (code == 0 && data) {
        const params = JSON.parse(data.data);
        refData.restart = params.restart;
        refData.restore = params.restore;
      }
      refData.loading = false;
    }
    return {
      ...toRefs(refData),
      sendMsg,
      getParams
    };
  }
});