import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-60912922"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Icon = _resolveComponent("Icon");
  const _component_el_button = _resolveComponent("el-button");
  const _component_BSJMap = _resolveComponent("BSJMap");
  const _component_Eldialog = _resolveComponent("Eldialog");
  return _openBlock(), _createBlock(_component_Eldialog, _mergeProps(_ctx.$attrs, {
    visible: _ctx.show,
    "onUpdate:visible": _cache[1] || (_cache[1] = $event => _ctx.show = $event),
    header: '围栏设置',
    onConfirmOk: _ctx.confirm,
    width: "1000px"
  }), {
    form: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("span", null, [_createVNode(_component_Icon, {
      onClick: _ctx.handleClearDraw,
      name: "icon-tuodongditu",
      style: {
        "font-size": "26px"
      },
      class: "icon"
    }, null, 8, ["onClick"]), _createVNode(_component_Icon, {
      onClick: _cache[0] || (_cache[0] = $event => _ctx.handleDraw(_ctx.type)),
      name: "Crop",
      style: {
        "font-size": "26px"
      },
      class: "icon"
    }), _createVNode(_component_el_button, {
      type: "primary",
      onClick: _ctx.confirm
    }, {
      default: _withCtx(() => [_createTextVNode("保存")]),
      _: 1
    }, 8, ["onClick"])]), _createVNode(_component_BSJMap, {
      ref: "refMap",
      zoom: 11,
      class: "refMap"
    }, null, 512)])]),
    _: 1
  }, 16, ["visible", "onConfirmOk"]);
}