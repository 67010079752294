import api from '@/api/instruction';
import { useMessage } from '@/hooks/web/useMessage';
import { ElCol, ElInput, ElRow, ElScrollbar, ElSwitch, ElTimeSelect, ElTimePicker } from 'element-plus';
import { defineComponent, reactive, toRefs, defineAsyncComponent } from 'vue';
import dayjs from 'dayjs';
export default defineComponent({
  name: 'StandbyMode',
  components: {
    ElRow,
    ElCol,
    ElInput,
    ElScrollbar,
    ElSwitch,
    ElTimeSelect,
    ElTimePicker,
    TypeSelect: defineAsyncComponent(() => import('@/components/TypeSelect/TypeSelect.vue'))
  },
  props: {
    imei: {
      type: String
    }
  },
  setup(props, {
    emit
  }) {
    const messageBox = useMessage();
    const refData = reactive({
      offorOn: true,
      loading: false,
      time: [new Date(dayjs().format('YYYY-MM-DD 23:00')), new Date(dayjs().format('YYYY-MM-DD 06:00'))]
    });
    async function sendMsg(params) {
      try {
        if ((!refData.time[0] || !refData.time[1]) && refData.offorOn) throw {
          msg: '请选择时间'
        };
        const param = {
          ...params,
          commandMsg: JSON.stringify({
            offorOn: refData.offorOn ? 1 : 0,
            timeStr: refData.offorOn ? `${dayjs(refData.time[0]).format('HHmm')}-${dayjs(refData.time[1]).format('HHmm')}` : `0`
          })
        };
        const result = await api.sendMsg(param);
        messageBox[result.code == 0 ? 'success' : 'error'](`指令下发${result.msg}`);
      } catch ({
        msg
      }) {
        messageBox.warning(msg);
      }
    }
    async function getParams() {
      const {
        data,
        code,
        msg
      } = await api.getCardCommandConf({
        mdtid: props.imei,
        commandType: 16
      });
      if (code == 0 && data) {
        const params = JSON.parse(data.data);
        refData.offorOn = params.offorOn ? true : false;
        refData.time = params.timeStr == '0' ? [new Date(dayjs().format('YYYY-MM-DD 23:00')), new Date(dayjs().format('YYYY-MM-DD 06:00'))] : params.timeStr.split('-').map(item => `${dayjs(dayjs().format(`YYYY-MM-DD ${item.substring(0, 2)}:${item.substring(2, 4)}`))}`);
      }
      refData.loading = false;
    }
    return {
      ...toRefs(refData),
      sendMsg,
      getParams
    };
  }
});