import api from '@/api/instruction';
import { useMessage } from '@/hooks/web/useMessage';
import { ElCol, ElInput, ElRow, ElSpace, ElScrollbar } from 'element-plus';
import { defineComponent, reactive, toRefs, defineAsyncComponent } from 'vue';
export default defineComponent({
  name: 'ServiceInfo',
  components: {
    ElRow,
    ElCol,
    ElInput,
    ElSpace,
    ElScrollbar,
    TypeSelect: defineAsyncComponent(() => import('@/components/TypeSelect/TypeSelect.vue'))
  },
  props: {
    mdtid: {
      type: String
    }
  },
  setup(props, {
    emit
  }) {
    const messageBox = useMessage();
    const refData = reactive({
      port: '',
      url: '',
      regUrl: /^(25[0-5]|2[0-4]\d|[0-1]\d{2}|[1-9]?\d)\.(25[0-5]|2[0-4]\d|[0-1]\d{2}|[1-9]?\d)\.(25[0-5]|2[0-4]\d|[0-1]\d{2}|[1-9]?\d)\.(25[0-5]|2[0-4]\d|[0-1]\d{2}|[1-9]?\d)$/,
      regPort: /^([0-9]|[1-9]\d{1,3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])$/,
      type: 1,
      regName: /^(?=^.{3,255}$)(http(s)?:\/\/)?(www\.)?[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+(:\d+)*(\/\w+\.\w+)*$/,
      loading: true
    });
    async function sendMsg(params) {
      try {
        if (!refData.url) throw {
          msg: '请填写地址'
        };
        if (!refData.regName.test(refData.url) && refData.type == 1) throw {
          msg: '请输入正确域名'
        };
        if (!refData.regUrl.test(refData.url) && refData.type == 2) throw {
          msg: '请输入正确IP地址'
        };
        if (!refData.port) throw {
          msg: '请填写端口'
        };
        if (!refData.regPort.test(refData.port)) throw {
          msg: '请输入正确端口'
        };
        const param = {
          ...params,
          commandMsg: JSON.stringify({
            port: refData.port,
            url: refData.url
          })
        };
        const result = await api.sendMsg(param);
        messageBox[result.code == 0 ? 'success' : 'error'](`指令下发${result.msg}`);
      } catch ({
        msg
      }) {
        messageBox.warning(msg);
      }
    }
    async function getParams() {
      refData.loading = true;
      const {
        data,
        code,
        msg
      } = await api.getCardCommandConf({
        mdtid: props.mdtid,
        commandType: 21
      });
      if (code == 0 && data) {
        const params = JSON.parse(data.data);
        refData.port = params.port;
        refData.url = params.url;
      }
      refData.loading = false;
    }
    return {
      ...toRefs(refData),
      sendMsg,
      getParams
    };
  }
});