import "core-js/modules/es.array.push.js";
import { computed, defineComponent, reactive, toRefs, defineAsyncComponent } from 'vue';
import api from '@/api/instruction';
import { useMessage } from '@/hooks/web/useMessage';
import { ElMessageBox } from 'element-plus';
import { useDebounce } from '@/hooks/core/useDebounce';
import dayjs from 'dayjs';
import { useStore } from "vuex";
export default defineComponent({
  name: 'Whitelist',
  components: {
    TypeSelect: defineAsyncComponent(() => import('@/components/TypeSelect/TypeSelect.vue'))
  },
  props: {
    imei: {
      type: String
    },
    //卡类型
    isInternet: {
      type: Number,
      default: 2
    },
    //修改时间
    sendTime: {
      type: String,
      default: ''
    },
    //设备类型
    deviceType: {
      type: String,
      default: 'SC01'
    }
  },
  emits: ['getStudent'],
  setup(props, {
    emit
  }) {
    const messageBox = useMessage();
    const {
      state
    } = useStore();
    const refData = reactive({
      list: [],
      getPhone: [],
      delPhones: [],
      callType: 2,
      dayList: [0, 1, 2, 3, 4, 5, 6],
      loading: true
    });
    // 客户代码
    const customCode = computed(() => state.userInfo.customCode);
    // [phone=HHmm-HHmm+HHmm-HHmm]
    const addPhones = computed(() => refData.list.map(({
      phone,
      times1,
      times2
    }) => `${phone}=${timeRangeStr(times1)}+${timeRangeStr(times2)}`));
    // [phone=HHmm-HHmm+HHmm-HHmm]
    const delPhones = computed(() => refData.delPhones.map(({
      phone,
      times1,
      times2
    }) => phone));
    function timeRangeStr(times) {
      return times.map(t => dayjs(t).format('HHmm')).join('-');
    }
    function deTimeStr(timeStr) {
      const times = timeStr.split('+').map(item => item.split('-'));
      return times.map(range => range.map(t => dayjs(dayjs().format(`YYYY-MM-DD ${t}`))));
    }
    function dePhoneStr(phoneStrs) {
      return phoneStrs.map(str => {
        const phone = str.split('=')[0];
        const [times1, times2] = deTimeStr(str.split('=')[1]);
        return {
          phone,
          times1,
          times2
        };
      });
    }
    // 下发
    async function sendMsg(params) {
      const regPhone = /^[0-9]*$/;
      if (props.sendTime && props.isInternet == 1) {
        return ElMessageBox.confirm(`温馨提示：本月已在${props.sendTime}修改过一次，本月修改次数已用，请下月一号再重新设置修改`, '提示', {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {}).catch(() => {});
      }
      try {
        if (!refData.dayList.length) throw {
          msg: '请选择周期'
        };
        refData.list.map((item, index) => {
          if (!item.phone) throw {
            msg: `请输入号码${index + 1}`
          };
          if (!regPhone.test(item.phone)) throw {
            msg: `号码${index + 1}不能输入英文字母`
          };
        });
        const param = {
          ...params,
          commandMsg: JSON.stringify({
            // [phone]
            delPhones: delPhones.value,
            // [phone=HHmm-HHmm+HHmm-HHmm]
            addPhones: addPhones.value,
            callType: addPhones.value.length ? 2 : 1,
            dayList: refData.dayList.join('+')
          })
        };
        const result = await api.sendMsg(param);
        messageBox[result.code == 0 ? 'success' : 'error'](`指令下发${result.msg}`);
        if (result.code == 0) {
          getParams();
          emit('getStudent');
        }
      } catch ({
        msg
      }) {
        messageBox.warning(msg);
      }
    }
    function add() {
      const times1 = [dayjs(dayjs().format('YYYY-MM-DD 00:00')), dayjs(dayjs().format('YYYY-MM-DD 23:59'))];
      const times2 = [dayjs(dayjs().format('YYYY-MM-DD 00:00')), dayjs(dayjs().format('YYYY-MM-DD 23:59'))];
      refData.list.push({
        phone: '',
        // [时段1,时段2]
        times1,
        times2
      });
    }
    function remove(active, phone) {
      const delPhone = refData.getPhone.find(p => p.phone === phone);
      if (delPhone) {
        refData.delPhones.push(delPhone);
      }
      refData.list = refData.list.filter((item, index) => index != active);
    }
    // 获取参数复现
    async function getParams() {
      try {
        const {
          data,
          code,
          msg
        } = await api.getCardCommandConf({
          mdtid: props.imei,
          commandType: 6
        });
        if (data && data.data) {
          const {
            delPhones,
            addPhones,
            callType,
            dayList
          } = JSON.parse(data.data);
          refData.callType = callType; //
          refData.dayList = dayList.split('+').map(num => Number(num));
          const list = dePhoneStr(addPhones);
          refData.getPhone = list;
          refData.list = list;
          !list.length && add();
        }
      } catch (err) {
        console.log(err);
      } finally {
        refData.loading = false;
      }
    }
    const [debounceRemove] = useDebounce(remove, 200);
    add(); // 默认添加一个
    return {
      ...toRefs(refData),
      sendMsg,
      add,
      debounceRemove,
      getParams,
      customCode
    };
  }
});