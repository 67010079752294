import { GET, POSTBody } from '../apiFn';
import url from '../url';
import APIType from './types';

export default {
  sendTransData:(params: APIType.SendTransDataParam): Promise<APIType.BaseRes> => POSTBody(url.sendTransData, params),
  sendMsg: (params: APIType.SendMsgParam): Promise<APIType.BaseRes> => POSTBody(url.sendMsg, params),
  sendFamilyPhoneMsg: (params: APIType.SendFamilyPhoneMsgParam): Promise<APIType.BaseRes> => POSTBody(url.sendFamilyPhoneMsg, params),
  sendSOSPhoneMsg: (params: APIType.SendSOSPhoneMsgParam): Promise<APIType.BaseRes> => POSTBody(url.sendSOSPhoneMsg, params),
  sendRemindMsg: (params: APIType.SendRemindMsgParam): Promise<APIType.BaseRes> => POSTBody(url.sendRemindMsg, params),
  sendWhiteMsg: (params: APIType.SendWhiteMsgParam): Promise<APIType.BaseRes> => POSTBody(url.sendWhiteMsg, params),
  getFamilyPhone: (params: APIType.getFamilyPhoneParam): Promise<APIType.getFamilyPhoneRes> => GET(url.getFamilyPhone, params),
  getSOSPhone: (params: APIType.getFamilyPhoneParam): Promise<APIType.getSOSPhoneRes> => GET(url.getSOSPhone, params),
  getRemind: (params: APIType.SendMsgParam): Promise<APIType.getRemindRes> => GET(url.getRemind, params),
  getWhiteList: (params: APIType.getFamilyPhoneParam): Promise<APIType.getWhiteListRes> => GET(url.getWhiteList, params),
  sendDisturb: (params: APIType.sendDisturbParam): Promise<APIType.BaseRes> => POSTBody(url.sendDisturb, params),
  getDisturbList: (params: APIType.getFamilyPhoneParam): Promise<APIType.getDisturbListRes> => GET(url.getDisturbList, params),
  publishMsg: (params: APIType.publishMsgParam): Promise<APIType.BaseRes> => POSTBody(url.publishMsg, params),
  getCardCommandConf: (params: APIType.getCardCommandConfParam): Promise<any> => GET(url.getCardCommandConf, params),
  batchSendDisturbBySchoolOrClass: (params: APIType.batchSendDisturbBySchoolOrClassParam): Promise<APIType.BaseRes> => POSTBody(url.batchSendDisturbBySchoolOrClass, params),
  publishMode: (params: APIType.publishModeParam): Promise<APIType.BaseRes> => POSTBody(url.publishMode, params),
}; 