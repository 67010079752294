import { defineComponent, reactive, toRefs } from 'vue';
import { ElRow, ElCol, ElSwitch } from 'element-plus';
import UploadType from './UploadType.vue';
import { useMessage } from '@/hooks/web/useMessage';
import api from '@/api/instruction';
export default defineComponent({
  name: 'InstructionNoticeType',
  components: {
    UploadType,
    ElRow,
    ElCol,
    ElSwitch
  },
  emits: ['update:modelValue', 'update:uploadType'],
  props: {
    imei: {
      type: String
    }
  },
  setup(props) {
    const messageBox = useMessage();
    const refData = reactive({
      onoff: 0
    });
    async function sendMsg(params) {
      const result = await api.sendMsg({
        cmdcontent: "",
        ...params
      });
      messageBox[result.code == 0 ? 'success' : 'error'](`指令下发${result.msg}`);
    }
    async function getParams(commandType) {
      const {
        data,
        code,
        msg
      } = await api.getCardCommandConf({
        mdtid: props.imei,
        commandType: 25
      });
      // if(data){
      //   refData.onoff = data.isRemind;
      //   refData.reportType = data.reportType;
      // }
    }
    return {
      ...toRefs(refData),
      sendMsg,
      getParams
    };
  }
});