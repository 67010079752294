import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-215ea562"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "wrapper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_input_number = _resolveComponent("el-input-number");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_scrollbar = _resolveComponent("el-scrollbar");
  const _directive_loading = _resolveDirective("loading");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives((_openBlock(), _createBlock(_component_el_scrollbar, null, {
    default: _withCtx(() => [_createVNode(_component_el_row, null, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 3
      }, {
        default: _withCtx(() => [_createTextVNode("定时回传间隔(秒)：")]),
        _: 1
      }), _createVNode(_component_el_col, {
        span: 15,
        offset: 1
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input_number, {
          modelValue: _ctx.min,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.min = $event),
          min: 2,
          max: 65535,
          onBlur: _cache[1] || (_cache[1] = val => _ctx.min = parseInt(_ctx.min.toString())),
          label: ""
        }, null, 8, ["modelValue"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })), [[_directive_loading, _ctx.loading]])]);
}