import api from '@/api/instruction';
import { useMessage } from '@/hooks/web/useMessage';
import { ElCol, ElInput, ElRow } from 'element-plus';
import { defineComponent, computed, reactive, toRefs, defineAsyncComponent } from 'vue';
import dayjs from "dayjs";
export default defineComponent({
  name: 'TransData',
  components: {
    ElRow,
    ElInput,
    ElCol,
    TypeSelect: defineAsyncComponent(() => import('@/components/TypeSelect/TypeSelect.vue'))
  },
  props: {
    mdtid: {
      type: String
    }
  },
  setup(props, {
    emit
  }) {
    const messageBox = useMessage();
    const refData = reactive({
      messageType: 65,
      sendresult: 0,
      time: '',
      sendtime: null,
      commandMsg: '',
      loading: false
    });
    const startTime = computed(() => {
      const HH = dayjs().format('HH');
      const mm = dayjs().format('mm');
      return !isTheDay.value ? '00:00' : `${HH}:${Number(mm) % 5 !== 0 ? Number(mm) + (5 - Number(mm) % 5) : mm}`;
    });
    // 判断当前选择时间是否为当天
    const isTheDay = computed(() => {
      return dayjs().format('YYYY-MM-DD') === refData.sendtime;
    });
    // 预计下发时间限制
    const disabledDate = time => {
      return time.getTime() + 24 * 60 * 60 * 1000 < Date.now();
    };
    async function sendMsg(params) {
      const param = {
        ...params,
        cmdtype: 14,
        cmddesc: refData.commandMsg,
        sendresult: refData.sendresult,
        sendtime: refData.sendtime,
        cmdcontent: JSON.stringify({
          param_type: refData.messageType,
          param_content: refData.commandMsg
        })
      };
      param.sendtime = refData.sendresult === -2 ? `${refData.sendtime} ${refData.time}:01` : '';
      const result = await api.sendMsg(param);
      console.log(result);
      messageBox[result.code == 0 ? 'success' : 'error'](`指令下发${result.msg}`);
    }
    async function getParams() {
      refData.loading = true;
      const {
        data,
        code,
        msg
      } = await api.getCardCommandConf({
        mdtid: props.mdtid,
        commandType: 26
      });
      if (code == 0 && data) {
        const params = JSON.parse(data.data);
        refData.commandMsg = params.msg;
      }
      refData.loading = false;
    }
    return {
      ...toRefs(refData),
      getParams,
      startTime,
      disabledDate,
      sendMsg
    };
  }
});